import React from "react";
import s from "./Heart.module.css";

import { ReactComponent as HeartSvg } from "../../assets/icons/hearts/heartSvg.svg";
import { ReactComponent as MainPadOnHeartSvg } from "../../assets/icons/hearts/mainPadOnHeartSvg.svg";
import { ReactComponent as BurntOutHeart } from "../../assets/icons/hearts/burnt_out_heart.svg";

const Heart = ({ mode = "" }) => {
  if (mode === "burnHeart") {
    return (
      <div className={s.container}>
        <HeartSvg className={s.heart} />
        <MainPadOnHeartSvg className={s.mainPadOnHeart} />
      </div>
    );
  } else {
    return <BurntOutHeart />;
  }
};

export default Heart;
