export default {
  header_logoName: "Знакомство с HTML и CSS. ",
  header_day: "День ",
  header_question: "Вопрос",
  header_modul: "Занятие",
  header_text: "из",
  buttonHideTheory_text: "Скрыть теорию и задание",
  congratsModal_title: "Поздравляем!",
  congratsModal_text1_1: "Ты успешно прошел ",
  congratsModal_text1_2:
    "-е занятие подготовительных заданий к домашней работе!",
  congratsModal_text2: "Твои результаты:",
  congratsModal_text3: " правильных ответов",
  congratsModal_text5_1: "Вы прошли первый день на ",
  congratsModal_text5_2: "других игроков! Продолжайте в том же духе ",
  congratsModal_text5_3: " быстрее, ",
  congratsModal_text5_4:
    "Теперь у вас есть доступ к просмотру обучающего видео Вани Лапы, где он показывает ",
  congratsModal_text5_5: " как сделать все 10 задач одной строчкой кода. ",
  congratsModal_text5_6: "Время просмотра: 14 минут ",
  congratsModal_text5_7: "Доступ к просмотру по кнопке ниже ",
  congratsModal_text5_8: "Вы прошли задание на ",
  congratsModal_text6_1: "Вы успешно решили задачи второго дня и сделали это ",
  congratsModal_text6_2: "других игроков! Не сбавляйте темп! ",
  congratsModal_text6_3: "Сегодня вместе с Ваней вы ",
  congratsModal_text6_4: "доделаете вёрстку из HTML-тегов левой части макета ",
  congratsModal_text6_5: "и скелет всего проекта уже будет готов ",
  congratsModal_text6_6: "Время просмотра: 11 минут ",
  congratsModal_text6_7: "Скорее приступайте по кнопке ниже ",
  congratsModal_text7_1: "Вы прошли самый сложный третий день ",
  congratsModal_text7_2: "чем ",
  congratsModal_text7_3: " других игроков! Это уже весомый результат ",
  congratsModal_text7_4: "Ура-ура, так держать ",
  congratsModal_text7_5:
    "Пора добавить красоты правой части вашего резюме с помощью CSS ",
  congratsModal_text7_6:
    "Нужно потрудиться на славу, чтобы уже совсем скоро получить желанный результат ",
  congratsModal_text7_7: "Время просмотра: 32 минуты ",
  congratsModal_text7_8: "Доступ к просмотру по кнопке ниже ",
  congratsModal_text8_1: "Четвертый день преоделён вами на ",
  congratsModal_text8_2: "других игроков, на горизонте уже финишные флаги ",
  congratsModal_text8_3:
    "Сегодня закончим с стилизацией левой части нашего макета ",
  congratsModal_text8_4:
    "Уже завтра вы будете у цели и будете любоваться своей персональной ссылкой на проект, не сбавляйте темп ",
  congratsModal_text8_5: "Время просмотра: 12 минут ",
  congratsModal_text8_6: "Заслуженное видео по кнопке ниже ",
  congratsModal_title_final: "Это абсолютная победа, поздравляем ",
  congratsModal_text9_1: "Вы завершили проект невероятно успешно - ",
  congratsModal_text9_2: "чем ",
  congratsModal_text9_3: " игроков. ",
  congratsModal_text9_4: "Мы очень-очень гордимся вами ",
  congratsModal_text9_5:
    "И конечно же ждём вашу ссылку на резюме в чате марафонцев ",
  congratsModal_text9_6:
    "И не стесняйтесь сегодня писать в общий чат, если возникают технические вопросы, ведь там уже много марафонцев, которые сдали свои резюме и готовы помочь остальным ",
  congratsModal_text9_7:
    "Уже скоро встретимся на финальном вебинаре, не пропустите ",
  congratsModal_text9_8: "Заветный бонус, ",
  congratsModal_text9_9:
    "после просмотра которого резюме и страница в Linkedin привлечет внимание HR-менеджеров топовых компаний ",
  congratsModal_text9_10:
    "Эту инструкцию мы сформировали на основе опыта трудоустройства более 1000 наших выпускников ",
  congratsModal_text9_11: "Жмите кнопку ниже, чтобы изучить ",
  ErrorInvalidTokenOrBlock_text: "Не валидная ссылка.",
  ErrorServerRefresh_text:
    "Технические работы на сервере, сервер перезагружается, обновите стратницу через 1-2 минуты.",
  ErrorServerStub_text:
    "Технические работы на сервере, попробуйте снова позже.",
  ErrorStub_text: "Не валидная ссылка.",
  footer_text: "Политика конфиденциальности",
  MobileAndTabletStub_text: "Расширь браузер или зайди с десктопа.",
  ModalAutotranslator_title: "Выключите автопереводчик !!!",
  ModalAutotranslator_text:
    "Потому что программа не сможет корректно проверить Ваше задание.",
  ModalRefreshQuestion_title:
    "Вы точно хотите сбросить код в начальное состояние?",
  ModalRefreshQuestion_yes: "Да",
  ModalRefreshQuestion_no: "Нет",
  ModalTaskCriteria_title: "Результат:",
  ModalTaskCriteria_success: "Успешно:",
  ModalTaskCriteria_errors: "Ошибки:",
  ModalTaskCriteria_buttonContinue: "Продолжить",
  ModalTaskCriteria_buttonNextQuestion: "Перейти к следующему вопросу",
  ModalTaskCriteria_buttonOk: "Ок",
  TaskButtons_btnCheck: "Проверить",
  TaskButtons_btnRefresh: "Сбросить",
  markup: "Макет",
  click_picture: "* чтобы увеличить, кликни по картинке",
  Timer_text: "Доступ к видеоподсказке через ",
  callToastify_taskAlreadySolved: "Этот вопрос уже решен!",
  callToastify_text1:
    "В этом задании HTML и CSS редакторы не активны, используй только редактор JavaScript",
  callToastify_text2:
    "В этом задании HTML и JavaScript редакторы не активны, используй только редактор CSS",
  callToastify_text3:
    "В этом задании CSS и JavaScript редакторы не активны, используй только редактор HTML",
  callToastify_text4:
    "В этом задании HTML редактор не активен, используй только редактор CSS",
  callToastify_text5:
    "В этом задании CSS редактор не активен, используй только редактор HTML",
  callToastify_text6:
    "В этом задании HTML редактор не активен, используй только редактор JavaScript",
  selectCurrentQuestion_text: "Вопрос",
  modalPolitics_title: "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ",
  modalPolitics_text:
    "Согласившись принимать участие в проверке заданий вы предоставляете согласие на обработку персональных данных, соглашаетесь с предложенными нами каналами коммуникации, с возможностью получения рассылок и коммерческих предложений. Принимая данную политику вы также подтверждаете, что ознакомились с перечнем своих прав как субъекта данных.",
  modalPolitics_subtitle1: "1. СБОР ЛИЧНОЙ ИНФОРМАЦИИ",
  modalPolitics_text1_1:
    "1.1. Во время заполнения контактных форм при регистрации мы просим вас сообщить необходимую личную информацию в объеме, который является необходимым для предоставления услуг.",
  modalPolitics_text1_2:
    "1.2. Файлы cookie - когда вы переходите по ссылкам, мы отправляем один или несколько файлов cookie на ваш компьютер или другое устройство. Файлы cookie используются для того, чтобы повышать качество предоставляемых услуг: сохранять настройки пользователя.",
  modalPolitics_text1_3:
    "1.3. Информация о посещении - при доступе на сайт наши серверы автоматически могут записывать определенную информацию. Эти журналы сервера могут содержать такую информацию, как веб-запрос, IP-адрес, тип и язык браузера, дату и время запроса.",
  modalPolitics_subtitle2: "2. ЗАЩИТА ИНФОРМАЦИИ",
  modalPolitics_text2_1:
    "2.1. Мы принимаем все необходимые меры для защиты данных от неавторизованного доступа, изменения, раскрытия или уничтожения. К этим мерам относятся, в частности, внутренняя проверка процессов сбора, хранения и обработки данных и мер безопасности, включая соответствующее шифрование и меры по обеспечению физической безопасности данных для предотвращения неавторизованного доступа.",
  modalPolitics_subtitle3: "3. ИЗМЕНЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ",
  modalPolitics_text3_1:
    "3.1. Настоящая политика конфиденциальности может время от времени меняться. Изменения, вносимые в политику конфиденциальности, будут опубликованы в этом документе.",
  ScoreRate_text: "Баллов: ",
  ScoreRateTooltip:
    "Количество заработанных баллов. За каждую выполненную задачу вы получаете 2 балла. Собирая баллы, вы открываете доступ к уровням, за которые получаете доступ к бонусам. Подробнее о бонусах в кнопке Информация.",
  LivelihoodIndicatorTooltip:
    "Количество доступных жизней. Жизни сгорают, если вы не решили задачи этого дня до 18:00. Если сгорят все жизни, вы не сможете больше решать задачи.",
  TaskButtonsVideo_tooltip: "Подсказка стоит 1 балл",
  LeftSidePanel_title: "Ваши достижения",
  LeftSidePanel_progress: "Прогресс:",
  LeftSidePanel_level: "Ваш уровень:",
  LeftSidePanel_bonus: "Ваши бонусы:",
  LeftSidePanel_nextLevel: "До следующего уровня баллов:",
  bonus: "Бонус ",
  videoLinksToBonus_bonusSoftSkills:
    "https://www.youtube.com/watch?v=232y1qYaYO4&t=1s",
  videoLinksToBonus_bonusFindJob: "https://www.youtube.com/watch?v=So9BjQpaHKU",
  videoLinksToBonus_linkedin: "https://www.youtube.com/watch?v=yAGRoxXM9EM",
  LeftSidePanel_inviteFriend: "Приглашенных друзей:",
  LeftSidePanel_lifes: "Доступно жизней:",
  ModalIntroductionInfo_title: "Правила марафона",
  ModalIntroductionInfo_text1:
    " прочтите эти правила #повнимательнее. Это поможет пройти марафон-игру до конца.",
  ModalIntroductionInfo_text0: "Старт",
  ModalIntroductionInfo_text2_1: "Итак, сейчас вы на ",
  ModalIntroductionInfo_text2_2: "1-м уровне - Hовичок (Beginner) -",
  ModalIntroductionInfo_text2_3:
    " решая задачи каждый день, вы зарабатываете баллы и можете открыть с их помощью доступ к крутым бонусам:",
  ModalIntroductionInfo_text3: "2 уровень - Trainee ",
  ModalIntroductionInfo_text4:
    "мастер-класс от  CEO GoIT Антона Черного на тему: ",
  ModalIntroductionInfo_text4_1:
    "Работа в IT-командах и ошибки при постановке целей. ",
  ModalIntroductionInfo_text4_2:
    "Разберём насколько важно иметь чёткие цели в разных сферах своей жизни и как их наличие влияет на ",
  ModalIntroductionInfo_text5: " возможность стать разработчиком",
  ModalIntroductionInfo_text6: "3 уровень - Junior -",
  ModalIntroductionInfo_text7:
    " инструкция от Инги Гадяцкой (EPAM Systems, Senior Talent Acquisition Specialist) на тему ",
  ModalIntroductionInfo_text7_1:
    "Руководство к действию: с чего начать поиск работы, после того как резюме готово ",
  ModalIntroductionInfo_text7_2:
    " Инга подготовила для вас разбор особенностей топовых ресурсов для размещения резюме и мониторинга рынка, лайфхаки для эффективного использования каждой из них ",
  ModalIntroductionInfo_text8_1: "Тех, кто закончит марафон ",
  ModalIntroductionInfo_text8_2: "в статусе Junior и сдаст свою ссылку",
  ModalIntroductionInfo_text8_3: ", ждет ",
  ModalIntroductionInfo_text8_4: "еще один бонус - ",
  ModalIntroductionInfo_text8_5:
    "доступ к инструкции о создании и оформлении резюме и профиля в LinkedIn для ",
  ModalIntroductionInfo_text8_6:
    "успешного старта карьеры и трудоустройства в IT-сфере. ",
  ModalIntroductionInfo_text8_7:
    "Эту инструкцию мы сформировали на основе опыта трудоустройства более 1000 наших выпускников ",
  ModalIntroductionInfo_text9: "Что вам нужно делать",
  ModalIntroductionInfo_text10: "Все просто: ",
  ModalIntroductionInfo_text10_1:
    "решайте задачи каждого дня и зарабатывайте баллы. ",
  ModalIntroductionInfo_text10_2: "1 задача = 2 балла. ",
  ModalIntroductionInfo_text10_3: "Сейчас вам начислено ",
  ModalIntroductionInfo_text10_4: "30 стартовых баллов, ",
  ModalIntroductionInfo_text10_5: "а ваш уровень ",
  ModalIntroductionInfo_text10_6: "- Beginner. ",
  ModalIntroductionInfo_text10_7: "Чтобы открыть первый бонус, уровень ",
  ModalIntroductionInfo_text10_7_1: "Trainee, ",
  ModalIntroductionInfo_text10_7_2: "вам нужно получить ",
  ModalIntroductionInfo_text10_8: "60 баллов. ",
  ModalIntroductionInfo_text10_9: "Второй бонус и уровень ",
  ModalIntroductionInfo_text10_10: "Junior - 90 баллов. ",
  ModalIntroductionInfo_text10_11:
    "Чтобы побороться за третий - нужно пройти все до конца.",
  ModalIntroductionInfo_text11:
    "В конце каждого дня вы будете получать доступ к обучающим видео Вани Лапы, где он показывает как сделать задачи “одной строчкой кода”",
  ModalIntroductionInfo_text11_1: "по итогу вместе сверстаете резюме ",
  ModalIntroductionInfo_text12: "Однако, не все так просто...",
  ModalIntroductionInfo_text13_1: "У вас есть всего 3 жизни. ",
  ModalIntroductionInfo_text13_2:
    "Если вы не решаете задачи дня до 18:00 следующего дня, жизнь сгорает, а с вашего счета снимается 5 баллов.",
  ModalIntroductionInfo_text13_3:
    "Когда сгорит последняя жизнь - Game Over, задачи станут для вас недоступны ",
  ModalIntroductionInfo_text14: "Видеоподсказки",
  ModalIntroductionInfo_text14_1:
    "Если чувствуете, что возникают сложности, воспользуйтесь видеоподсказкой. Вы можете ее взять в любой задаче задачe",
  ModalIntroductionInfo_text14_2: "Однако обратите внимание: ",
  ModalIntroductionInfo_text14_3: "каждая подсказка стоит 1 балл ",
  TaskButtons_btnVideo: "Видеоподсказка",
  button_share: "Поделиться с другом",
  ButtonShareWithAFriendText: "Поделиться с другом",
  button_go: "Погнали!",
  button_watch: "Смотреть видео ",
  cool_instruction: "Смотреть ",
  youtube_1: "https://youtu.be/7pMOogr1h00 ",
  youtube_2: "https://youtu.be/TfU3EAMqVOA  ",
  youtube_3: "https://youtu.be/It9iiBs6WCg ",
  youtube_4: "https://youtu.be/7bnzN-Sdwx0 ",
  youtube_5: "https://youtu.be/yAGRoxXM9EM ",
  ModalIntroductionInfo_text_15: "Как еще вы можете заработать баллы",
  ModalIntroductionInfo_text15_1:
    "Пригласите друга: как только он зарегистрируется, вам и ему будет начислено по 5 баллов. Больше друзей - больше баллов ",
  ModalIntroductionInfo_text16: "Как отследить свой прогресс",
  ModalIntroductionInfo_text16_1:
    "- универсальная кнопка с информацией о вашем прогрессе. Нажав на нее, вы можете посмотреть:",
  ModalIntroductionInfo_text16_2: "на каком уровне находитесь;",
  ModalIntroductionInfo_text16_3:
    "сколько баллов заработали сейчас и сколько осталось до следующего уровня;",
  ModalIntroductionInfo_text16_4:
    "свои бонусы, когда достигнете уровней Trainee и Junior;",
  ModalIntroductionInfo_text16_5: "жизни - сколько у вас осталось.",
  ModalIntroductionInfo_text17: "Быстрый доступ в верхней панели",
  ModalIntroductionInfo_text18: "- количество баллов.",
  ModalIntroductionInfo_text19: "       - ваши жизни.",
  ModalIntroductionInfo_text20: "Ваша реферальная кнопка",
  ModalIntroductionInfo_text21:
    "это ваша персональная кнопка для приглашения в игру. Она генерирует реферальную ссылку, которую вы можете отправить друзьям. Это позволит им присоединиться к игре, а еще - заработать дополнительно по 5 баллов каждому из вас. Решайте задачи вместе (только чур не подглядывать!) - так интереснее. ",
  ModalIntroductionInfo_text22: "Если вы что-то пропустили...",
  ModalIntroductionInfo_text23:
    "Вы всегда можете открыть это окно снова, нажав на значок информации в нижней панели.",
  ModalIntroductionInfo_text24: "Я прочитал/ла правила игры, мне все понятно",
  toastifyFunction_error:
    "Сначала прочтите правила игры и поставьте галочку, что ознакомились с правилами!",
  ToastifyFunction_isNotTelegramUser:
    "Не правильный токен, данный пользователь не зарегистрирован в telegram!",
  ErrorEndedLives_text:
    "Нам жаль, но игра для вас закончилась. Чтобы выучить программирование, нужно регулярно заниматься. Без ежедневных занятий не получится выучить HTML/CSS",
  progressTittle: "Поздравляем   ",
  trainee_1: "Вы достигли уровня ",
  trainee_2:
    "и открыли первый бонус - мастер-класс от  CEO GoIT Антона Черного на тему: ",
  trainee_3: "“Работа в IT-командах и ошибки при постановке целей”. ",
  trainee_4:
    "Разберём насколько важно иметь чёткие цели в разных сферах своей жизни и как их наличие влияет на ",
  trainee_5: "возможность стать разработчиком ",
  trainee_6:
    "Просмотреть бонус вы сможете в любое время, нажав на кнопку Спидометр ",
  trainee_level: "“Trainee” ",
  junior_1: "Теперь вы достигли уровня ",
  junior_2: "Ура-ура, так держать ",
  junior_3:
    "Вы на уровень выше и заслужили бонус  - инструкция от Инги Гадяцкой (EPAM Systems, Senior Talent Acquisition Specialist) на тему ",
  junior_4:
    "“Руководство к действию: с чего начать поиск работы, после того как резюме готово” ",
  junior_5:
    "Инга подготовила для вас разбор особенностей топовых ресурсов для размещения резюме и мониторинга рынка, лайфхаки для эффективного использования каждой из них ",
  junior_level: "“Junior”! ",
  lifeWithdrawalNotification1:
    "❗️С вашего счета списан 1 балл за использование подсказки 👌",
  lifeWithdrawalNotification2:
    "❗️Минус 1 балл 😬 Попробуйте в следующий раз без подсказки. Все получится 😊",
  lifeWithdrawalNotification3:
    "❗️Вы потеряли 1 балл за использование подсказки.",
  ModalShareWithFriend_title: "Поделиться с другом",
  ModalShareWithFriend_mainText:
    "Скопируйте текст со ссылкой ниже, отправьте его друзьям или опубликуйте в соц.сетях. Они тоже смогут сыграть ",
  ModalShareWithFriend_mainText2_1: "💥 Бонус: ",
  ModalShareWithFriend_mainText2_2:
    "когда друг зарегистрируется, вы оба получите по 5 баллов на счет.",
  ModalShareWithFriend_refText1:
    "Я участвую в марафоне от GoIT. Совсем скоро я создам свой первый проект - страницу резюме.",
  ModalShareWithFriend_refText2:
    "Хотите попробовать web-программирование и разобраться с основами html и css? Присоединяйтесь по моей ссылке, чтобы получить дополнительные баллы на счет и быстрее открыть доступ к бонусам.",
  ModalInvitedFriend_text:
    "🔥 Круто, ваш друг стал участником игры. На ваш счет зачислено плюс 5 баллов.",
  ModalRefInvited_text:
    "💥 Поздравляем! Вы получили дополнительные 5 баллов за участие в реферальной системе.",
  LeftSidePanel_video: "Обучающие материалы:",
  video: "Видео ",
  taskSuccessesNotification1: "Поздравляем! Вы заработали 2 балла.",
  taskSuccessesNotification2: "Отлично! Вы заработали новые баллы.",
  taskSuccessesNotification3: "Плюс 2 балла. Так держать!",
  taskSuccessesNotification4: "Ура! Ещё два балла на счёт!",
  taskSuccessesNotification5: "Ура, дополнительные баллы!",
  taskSuccessesNotification6: "Рейтинг всё больше и больше!",
  taskSuccessesNotification7: "+2 балла, скоро бонус!",
  taskSuccessesNotification8: "Юхууу, скоро новый уровень!",
  taskSuccessesNotification9: "Так держать! Больше баллов к рейтингу!",
  taskSuccessesNotification10: "Вот это скорость, молодец!",
  congrats_preTrainee_1: "Вы совсем близко к уровню ",
  congrats_preTrainee_2: ". Скоро вы откроете доступ к",
  congrats_preTrainee_3: "видеоинструкции 🔥",
  congrats_preJunior_1: "Скоро вам откроется доступ к ",
  congrats_preJunior_2: "самому крутому - бонусу уровня Junior! 😎 ",
  congrats_preJunior_3:
    "Осталось всего несколько задач до следующего уровня 😉 Поднажмите 💪 ",
};
