import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import s from "./ModalPolitics.module.css";
import { FormattedMessage } from "react-intl";

const ModalPolitics = ({ onClose }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.content}>
        <Close onClick={onClose} className={s.close} />
        <h2 className={s.modalMainTitle}>
          <FormattedMessage id="modalPolitics_title" />
        </h2>
        <p className={s.text}>
          <FormattedMessage id="modalPolitics_text" />
        </p>

        <h3 className={s.title}>
          <FormattedMessage id="modalPolitics_subtitle1" />
        </h3>
        <p className={s.text}>
          <FormattedMessage id="modalPolitics_text1_1" />
        </p>
        <p className={s.text}>
          <FormattedMessage id="modalPolitics_text1_2" />
        </p>
        <p className={s.text}>
          <FormattedMessage id="modalPolitics_text1_3" />
        </p>

        <h3 className={s.title}>
          <FormattedMessage id="modalPolitics_subtitle2" />
        </h3>
        <p className={s.text}>
          <FormattedMessage id="modalPolitics_text2_1" />
        </p>

        <h3 className={s.title}>
          <FormattedMessage id="modalPolitics_subtitle3" />
        </h3>
        <p className={s.text}>
          <FormattedMessage id="modalPolitics_text3_1" />
        </p>
      </div>
    </ModalWrapper>
  );
};

export default ModalPolitics;
