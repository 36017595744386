export default function blockDefinition(blockHash) {
  switch (blockHash) {
    case "juf31geq":
      return 1;
    case "74gfhj7l":
      return 2;
    case "jgfhk855":
      return 3;
    case "f5368942":
      return 4;
    case "fsa64rij":
      return 5;
    case "546jh547":
      return 6;
    default:
      return "";
  }
}
