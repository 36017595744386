import React, { useEffect, useCallback, useRef, useContext } from "react";
import s from "./LeftSidePanel.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { FormattedMessage } from "react-intl";
import LivelihoodIndicator from "../LivelihoodIndicator/LivelihoodIndicator";
import { ReactComponent as InfoSvg } from "../../assets/icons/info/info.svg";
import defineUserLevelAndNextLevelCount from "../../utils/defineUserLevelAndNextLevelCount";
import userMarathonVariables from "../../utils/userMarathonVariables";
import languages from "../../languages";
import Context from "../../context/context";

const LeftSidePanel = ({
  onClose,
  block,
  showModalIntroductionInfo,
  userGameVariable,
  videoEducation,
  isBonusLinkedinAvailable,
}) => {
  const wrapperRef = useRef();
  const context = useContext(Context);
  const handleBackdropClick = (e) => {
    if (wrapperRef.current && e.target !== wrapperRef.current) {
      return;
    }
    onClose();
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.code !== "Escape") {
        return;
      }
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    document.querySelector("body").style.overflow = "hidden";
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      document.querySelector("body").style.overflow = "visible";
    };
  }, [handleKeyPress]);

  let level = defineUserLevelAndNextLevelCount(
    userGameVariable.currentScore
  ).level;
  let nextLevelCount = defineUserLevelAndNextLevelCount(
    userGameVariable.currentScore
  ).nextLevelCount;
  const { lang } = context;
  return (
    <div onClick={handleBackdropClick} className={s.wrapper} ref={wrapperRef}>
      <div className={s.panel}>
        <Close onClick={onClose} className={s.close} />

        <div className={s.content}>
          <h2 className={s.title}>
            <FormattedMessage id="LeftSidePanel_title" />
          </h2>

          <div className={s.text}>
            <span>
              <FormattedMessage id="LeftSidePanel_progress" />
            </span>
            <span className={s.count}>
              {userGameVariable.currentScore}/{userMarathonVariables.maxScore}
            </span>
          </div>

          <div className={s.text}>
            <span>
              <FormattedMessage id="LeftSidePanel_level" />
            </span>
            <span className={s.level}>{level}</span>
          </div>

          {level === "Trainee" && (
            <div className={s.text}>
              <span>
                <FormattedMessage id="LeftSidePanel_bonus" />
              </span>
              <div className={s.blockBonus}>
                <p className={s.link}>
                  <a
                    href={languages[lang].videoLinksToBonus_bonusSoftSkills}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="bonus" />1
                  </a>
                </p>
              </div>
            </div>
          )}
          {level === "Junior" && (
            <div className={s.text}>
              <p>
                <FormattedMessage id="LeftSidePanel_bonus" />
              </p>
              <div className={s.blockBonus}>
                <p className={s.link}>
                  <a
                    href={languages[lang].videoLinksToBonus_bonusSoftSkills}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="bonus" />1
                  </a>
                </p>
                <p className={s.link}>
                  <a
                    href={languages[lang].videoLinksToBonus_bonusFindJob}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="bonus" /> 2
                  </a>
                </p>
                {isBonusLinkedinAvailable && (
                  <p className={s.link}>
                    <a
                      href={languages[lang].videoLinksToBonus_linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="bonus" />3
                    </a>
                  </p>
                )}
              </div>
            </div>
          )}

          <div className={s.text}>
            <span>
              <FormattedMessage id="LeftSidePanel_nextLevel" />
            </span>
            <span>{nextLevelCount}</span>
          </div>

          <div className={s.text}>
            <span>
              <FormattedMessage id="LeftSidePanel_inviteFriend" />
            </span>
            <span>{userGameVariable.invitedFriendCount}</span>
          </div>

          <div className={s.text}>
            <span>
              <FormattedMessage id="LeftSidePanel_lifes" />
            </span>
            {userGameVariable.currentLiveCount && (
              <LivelihoodIndicator
                numberOfLives={userGameVariable.currentLiveCount}
                presentaionRole={true}
              />
            )}
          </div>
          {(videoEducation["1"] ||
            videoEducation["2"] ||
            videoEducation["3"] ||
            videoEducation["4"]) && (
            <div className={s.text}>
              <FormattedMessage id="LeftSidePanel_video" />
              <div className={s.blockBonus}>
                {videoEducation["1"] && (
                  <p className={s.link}>
                    <a
                      href={languages[lang].youtube_1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="video" />1
                    </a>
                  </p>
                )}

                {videoEducation["2"] && (
                  <p className={s.link}>
                    <a
                      href={languages[lang].youtube_2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="video" />2
                    </a>
                  </p>
                )}
                {videoEducation["3"] && (
                  <p className={s.link}>
                    <a
                      href={languages[lang].youtube_3}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="video" />3
                    </a>
                  </p>
                )}
                {videoEducation["4"] && (
                  <p className={s.link}>
                    <a
                      href={languages[lang].youtube_4}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="video" />4
                    </a>
                  </p>
                )}
              </div>
            </div>
          )}

          <InfoSvg className={s.info} onClick={showModalIntroductionInfo} />
        </div>
      </div>
    </div>
  );
};

export default LeftSidePanel;
