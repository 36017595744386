import React, { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import ModalPolitics from "../ModalPolitics/ModalPolitics";
import Button from "../Button/Button";
import { ReactComponent as InfoSvg } from "../../assets/icons/info/info.svg";
import languages from "../../languages";
import Context from "../../context/context";

import s from "./Footer.module.css";

const Footer = ({
  footerTextRef,
  showModalIntroductionInfo,
  showModalShareWithFriend,
  clientWidth,
}) => {
  const [isModalPoliticsOpen, setIsModalPoliticsOpen] = useState(false);
  const openModalPolitics = () => setIsModalPoliticsOpen(true);
  const closeModalPolitics = () => setIsModalPoliticsOpen(false);
  const context = useContext(Context);
  const { lang } = context;

  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className={s.footer_container}>
      <div className={s.footer_content}>
        <div className={s.info} onClick={showModalIntroductionInfo}>
          <InfoSvg />
          {clientWidth > 900 && <span className={s.infoText}></span>}
        </div>
        <p className={s.copyright}>
          <span className={s.signOfCopyright}>&copy;</span> {year}
          <span className={s.signOfDivider}>|</span>
          <span
            className={s.privacyPolicy}
            onClick={openModalPolitics}
            ref={footerTextRef}
          >
            <FormattedMessage id="footer_text" />
          </span>
        </p>
        <Button
          onClickButton={showModalShareWithFriend}
          text={languages[lang].ButtonShareWithAFriendText}
        />
      </div>
      {isModalPoliticsOpen && <ModalPolitics onClose={closeModalPolitics} />}
    </footer>
  );
};

export default Footer;
