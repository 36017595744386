import React, { useRef, useState, useContext } from "react";

import languages from "../../languages";
import Context from "../../context/context";

import s from "./TextAreaWithCopy.module.css";

export default function CopyExample({ linkToCopy }) {
  const [copySuccess, setCopySuccess] = useState("Copy");
  const textAreaRef = useRef(null);

  const context = useContext(Context);
  const { lang } = context;

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }

  return (
    <div className={s.container}>
      {
        /* Logical shortcut for only displaying the 
          button if the copy command exists */
        document.queryCommandSupported("copy") && (
          <div className={s.buttonContainer}>
            <button className={s.button} onClick={copyToClipboard}>
              {copySuccess}
            </button>
          </div>
        )
      }
      <form>
        <textarea
          className={s.textarea}
          value={`${languages[lang].ModalShareWithFriend_refText1}\n\n${languages[lang].ModalShareWithFriend_refText2}\n\n${linkToCopy}`}
          ref={textAreaRef}
          cols="30"
          rows="8"
        />
      </form>
    </div>
  );
}
