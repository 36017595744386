import React, { useContext } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./CongratsModal.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
import utilsFunctionBlockDefinition from "../../utils/blockDefinition";
import { FormattedMessage } from "react-intl";
import languages from "../../languages";
import Context from "../../context/context";

const CongratsModal = ({
  onClose,
  blockTasksArr,
  block,
  currentTaskId,
  speedResult,
  clientWidth,
}) => {
  const context = useContext(Context);
  const { lang } = context;

  const blockIndex = utilsFunctionBlockDefinition(block);

  return (
    <ModalWrapper
      onClose={() => {
        onClose();
      }}
      hideCloseButton
    >
      {blockIndex === 1 && currentTaskId === "html-3" && (
        <section className={s.wrapper}>
          <WinnerCup className={s.winnerCup} width="118" />
          <h2 className={s.modalTitle}>
            <FormattedMessage id="congratsModal_title" />
          </h2>
          <Close className={s.close} onClick={onClose} />
          <div className={s.contentWrapper}>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text5_8" />
              <span className={s.fatText}>{speedResult}% </span>
              <FormattedMessage id="congratsModal_text5_3" />
              <FormattedMessage id="congratsModal_text5_2" />
              <span role="img" aria-label="smile">
                😉
              </span>
            </p>
          </div>
        </section>
      )}
      {blockIndex === 1 && currentTaskId === "html-10" && (
        <section className={s.wrapper}>
          <WinnerCup className={s.winnerCup} width="118" />
          <h2 className={s.modalTitle}>
            <FormattedMessage id="congratsModal_title" />
          </h2>
          <Close className={s.close} onClick={onClose} />
          <div className={s.contentWrapper}>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text5_1" />
              <span className={s.fatText}>{speedResult}% </span>
              <FormattedMessage id="congratsModal_text5_3" />
              <FormattedMessage id="congratsModal_text5_2" />
              <span role="img" aria-label="smile">
                😉
              </span>
            </p>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text5_4" />
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text5_5" />
              </span>
              <FormattedMessage id="congratsModal_text5_6" />
              <span role="img" aria-label="clock">
                ⏰
              </span>
              <FormattedMessage id="congratsModal_text5_7" />
              <span role="img" aria-label="show_down">
                👇
              </span>
            </p>
            <p></p>
            <div className={s.containerIconWithText}>
              <div className={s.iconContainer}>
                <a
                  className={s.button}
                  href={languages[lang].youtube_1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="button_watch" />
                </a>
              </div>
            </div>
          </div>
        </section>
      )}

      {blockIndex === 2 && (
        <section className={s.wrapper}>
          <WinnerCup className={s.winnerCup} width="118" />
          <h2 className={s.modalTitle}>
            <FormattedMessage id="congratsModal_title" />
          </h2>
          <Close className={s.close} onClick={onClose} />
          <div className={s.contentWrapper}>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text6_1" />
              <span className={s.fatText}>{speedResult}%</span>
              <FormattedMessage id="congratsModal_text5_3" />
              <FormattedMessage id="congratsModal_text6_2" />
              <span role="img" aria-label="force">
                💪
              </span>
            </p>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text6_3" />
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text6_4" />
              </span>
              <FormattedMessage id="congratsModal_text6_5" />
              <span role="img" aria-label="smile">
                😎
              </span>
              <p>
                <FormattedMessage id="congratsModal_text6_6" />
                <span role="img" aria-label="clock">
                  ⏰
                </span>
                <FormattedMessage id="congratsModal_text6_7" />
                <span role="img" aria-label="show_down">
                  👇
                </span>
              </p>
            </p>
            <p></p>
            <div className={s.containerIconWithText}>
              <div className={s.iconContainer}>
                <a
                  className={s.button}
                  href={languages[lang].youtube_2}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="button_watch" />
                </a>
              </div>
            </div>
          </div>
        </section>
      )}

      {blockIndex === 3 && (
        <section className={s.wrapper}>
          <WinnerCup className={s.winnerCup} width="118" />
          <h2 className={s.modalTitle}>
            <FormattedMessage id="congratsModal_title" />
          </h2>
          <Close className={s.close} onClick={onClose} />
          <div className={s.contentWrapper}>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text7_1" />
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text5_3" />
              </span>
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text7_2" />{" "}
              </span>
              <span className={s.fatText}>{speedResult}%</span>

              <FormattedMessage id="congratsModal_text7_3" />
              <span role="img" aria-label="smile">
                😉
              </span>
              <FormattedMessage id="congratsModal_text7_4" />
              <span role="img" aria-label="like">
                👍
              </span>
            </p>
            <p className={s.text}>
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text7_5" />
              </span>
              <span role="img" aria-label="fire">
                🔥
              </span>
              <FormattedMessage id="congratsModal_text7_6" />
              <span role="img" aria-label="smile">
                😍
              </span>
              <p>
                <FormattedMessage id="congratsModal_text7_7" />
                <span role="img" aria-label="clock">
                  ⏰
                </span>
                <FormattedMessage id="congratsModal_text7_8" />
                <span role="img" aria-label="show_down">
                  👇
                </span>
              </p>
            </p>
            <p></p>
            <div className={s.containerIconWithText}>
              <div className={s.iconContainer}>
                <a
                  className={s.button}
                  href={languages[lang].youtube_3}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="button_watch" />
                </a>
              </div>
            </div>
          </div>
        </section>
      )}

      {blockIndex === 4 && (
        <section className={s.wrapper}>
          <WinnerCup className={s.winnerCup} width="118" />
          <h2 className={s.modalTitle}>
            <FormattedMessage id="congratsModal_title" />
          </h2>
          <Close className={s.close} onClick={onClose} />
          <div className={s.contentWrapper}>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text8_1" />
              <span className={s.fatText}>{speedResult}%</span>

              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text5_3" />
              </span>

              <FormattedMessage id="congratsModal_text8_2" />
              <span role="img" aria-label="flag">
                🏁
              </span>
            </p>
            <p className={s.text}>
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text8_3" />
              </span>
              <span role="img" aria-label="smile">
                😊
              </span>
              <FormattedMessage id="congratsModal_text8_4" />
              <span role="img" aria-label="rocket">
                🚀
              </span>
              <p>
                <FormattedMessage id="congratsModal_text8_5" />
                <span role="img" aria-label="clock">
                  ⏰
                </span>
                <FormattedMessage id="congratsModal_text8_6" />
                <span role="img" aria-label="show_down">
                  👇
                </span>
              </p>
            </p>
            <p></p>
            <div className={s.containerIconWithText}>
              <div className={s.iconContainer}>
                <a
                  className={s.button}
                  href={languages[lang].youtube_4}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="button_watch" />
                </a>
              </div>
            </div>
          </div>
        </section>
      )}

      {blockIndex === 5 && (
        <section className={s.wrapper}>
          <WinnerCup className={s.winnerCup} width="50" />
          <h2 className={s.modalTitleFinal}>
            <FormattedMessage id="congratsModal_title_final" />
            <span role="img" aria-label="fire">
              🔥
            </span>
            <span role="img" aria-label="rocket">
              🚀
            </span>
            <span role="img" aria-label="hands">
              👏
            </span>
          </h2>
          <Close className={s.close} onClick={onClose} />
          <div className={s.contentWrapper}>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text9_1" />

              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text5_3" />
              </span>
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text9_2" />
              </span>
              <span className={s.fatText}>{speedResult}%</span>
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text9_3" />
              </span>
              <FormattedMessage id="congratsModal_text9_4" />
              <span role="img" aria-label="force">
                💪
              </span>
            </p>
            <p className={s.text}>
              <FormattedMessage id="congratsModal_text9_5" />

              <span role="img" aria-label="smile">
                😍
              </span>
              <FormattedMessage id="congratsModal_text9_6" />
              <span role="img" aria-label="smile">
                😎
              </span>
              <span className={s.fatText}>
                <FormattedMessage id="congratsModal_text9_7" />
              </span>
              <span role="img" aria-label="smile">
                😱
              </span>

              <p>
                <span className={s.fatText}>
                  <FormattedMessage id="congratsModal_text9_8" />
                </span>
                <FormattedMessage id="congratsModal_text9_9" />
                <span role="img" aria-label="smile">
                  😎
                </span>
                <FormattedMessage id="congratsModal_text9_10" />
                <span role="img" aria-label="present">
                  🎁
                </span>
              </p>
              <p>
                <FormattedMessage id="congratsModal_text9_11" />
                <span role="img" aria-label="show_down">
                  👇
                </span>
              </p>
            </p>

            <div className={s.containerIconWithText}>
              <div className={s.iconContainer}>
                <a
                  className={s.button}
                  href={languages[lang].youtube_5}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="cool_instruction" />
                  <span role="img" aria-label="rocket">
                    🚀
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      )}
    </ModalWrapper>
  );
};

export default CongratsModal;
