import React from "react";
import logo from "../../assets/images/logo.png";
import { FormattedMessage } from "react-intl";
import s from "./ErrorEndedLives.module.css";

const ErrorEndedLives = () => {
  return (
    <div className={s.container}>
      <a href="http://goit.ua/" target="_blank" rel="noopener noreferrer">
        <img className={s.logo} src={logo} alt="logo" />
      </a>
      <h2 className={s.title}>
        Game over{" "}
        <span role="img" aria-label="sad smile">
          &#128549;
        </span>
      </h2>

      <p className={s.text}>
        <FormattedMessage id="ErrorEndedLives_text" />
      </p>
    </div>
  );
};

export default ErrorEndedLives;
