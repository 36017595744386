import React from "react";
import { ReactComponent as Extend } from "../../assets/icons/fullscreen.svg";
import { ReactComponent as Mobile } from "../../assets/icons/mobile2.svg";
import { ReactComponent as Tablet } from "../../assets/icons/tablet/tablet.svg";
import s from "./BrowserHeader.module.css";

const BrowserHeader = ({
  openModalRefreshQuestion,
  openBrowserResultModal,
  isMobileViewSelected,
  selectMobileView,
  cancelSelectMobileView,
  isTabletViewSelected,
  selectTabletView,
  cancelTabletView,
}) => {
  return (
    <div className={s.container}>
      <button onClick={openModalRefreshQuestion} className={s.button}></button>
      <div>
        <button
          onClick={
            isMobileViewSelected ? cancelSelectMobileView : selectMobileView
          }
          className={s.button}
          style={{ marginRight: "20px" }}
        >
          <Mobile className={isMobileViewSelected ? s.iconSelected : s.icon2} />
        </button>
        <button
          onClick={isTabletViewSelected ? cancelTabletView : selectTabletView}
          className={s.button}
          style={{ marginRight: "20px" }}
        >
          <Tablet className={isTabletViewSelected ? s.iconSelected : s.icon2} />
        </button>
        <button onClick={openBrowserResultModal} className={s.button}>
          <Extend className={s.icon2} />
        </button>
      </div>
    </div>
  );
};

export default BrowserHeader;
