import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./ModalAutotranslator.module.css";
import { FormattedMessage } from "react-intl";

const ModalAutotranslator = ({ onClose }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.wrapper}>
        <h2 className={s.title}>
          <FormattedMessage id="ModalAutotranslator_title" />
        </h2>
        <p className={s.text}>
          <FormattedMessage id="ModalAutotranslator_text" />
        </p>
        {/* <p className={s.text}>
          <a
            href="https://www.youtube.com/watch?v=DNjeGQ3DqKk&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Посилання на відео, де розповідається як вимкнути автоперекладач.
          </a>
        </p> */}
      </div>
    </ModalWrapper>
  );
};

export default ModalAutotranslator;
