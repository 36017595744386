export default function defineUserLevelAndNextLevelCount(score) {
  if (score <= 59) {
    const level = "Beginner";
    const nextLevelCount = 60 - score;

    return {
      level,
      nextLevelCount,
    };
  } else if (score >= 60 && score <= 89) {
    const level = "Trainee";
    const nextLevelCount = 90 - score;

    return {
      level,
      nextLevelCount,
    };
  } else if (score >= 90) {
    const level = "Junior";
    const nextLevelCount = 0;

    return {
      level,
      nextLevelCount,
    };
  }
}
