import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import s from "./ModalInvitedFriend.module.css";
import { FormattedMessage } from "react-intl";

const ModalInvitedFriend = ({ onClose }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.wrapper}>
        <Close className={s.close} onClick={onClose} />
        <p className={s.text}>
          <FormattedMessage id="ModalInvitedFriend_text" />
        </p>
      </div>
    </ModalWrapper>
  );
};

export default ModalInvitedFriend;
