import React, { useContext } from "react";
import s from "./ModalIntroductionInfo.module.css";
import { FormattedMessage } from "react-intl";
import Speedometer from "../../components/Speedometer/Speedometer";
import ScoreRate from "../../components/ScoreRate/ScoreRate";
import LivelihoodIndicator from "../../components/LivelihoodIndicator/LivelihoodIndicator";
import Button from "../../components/Button/Button";
import { ReactComponent as InfoSvg } from "../../assets/icons/info/info.svg";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
import languages from "../../languages";
import Context from "../../context/context";

const ModalIntroductionInfo = ({
  hideModalIntroductionInfo,
  isUserAgreeWithGameRules,
  userGameVariable,
  handleChangeUserAgreeWithGameRules,
  startGameOrContinue,
  telegramUserInfo,
  clientWidth,
}) => {
  let userName = "UserName";
  const context = useContext(Context);
  const { lang } = context;

  return (
    <div className={s.backdrop}>
      <div className={s.modal_wrapper}>
        {isUserAgreeWithGameRules && (
          <Close className={s.close} onClick={hideModalIntroductionInfo} />
        )}

        <h2 className={s.title}>
          <FormattedMessage id="ModalIntroductionInfo_title" />
        </h2>
        <div className={s.content}>
          <p className={s.text}>
            {telegramUserInfo.firstName ? telegramUserInfo.firstName : userName}
            ,
            <FormattedMessage id="ModalIntroductionInfo_text1" />
          </p>
          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text0" />
          </h3>
          <p className={s.text}>
            <FormattedMessage id="ModalIntroductionInfo_text2_1" />
            <span className={s.checkMark}>&#10004;</span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text2_2" />
            </span>
            <FormattedMessage id="ModalIntroductionInfo_text2_3" />
          </p>

          <ul className={s.levelsList}>
            <li>
              <span className={s.checkMark}>&#10004;</span>
              <span className={s.fatText}>
                <FormattedMessage id="ModalIntroductionInfo_text3" />
              </span>{" "}
              <span role="img" aria-label="man with computer icon">
                👨‍💻
              </span>
              <span>
                <FormattedMessage id="ModalIntroductionInfo_text4" />
              </span>
              <span className={s.fatText}>
                <FormattedMessage id="ModalIntroductionInfo_text4_1" />
              </span>
              <span>
                <FormattedMessage id="ModalIntroductionInfo_text4_2" />
              </span>
              <span className={s.fatText}>
                <FormattedMessage id="ModalIntroductionInfo_text5" />
                💪
              </span>
            </li>
            <li>
              <span className={s.checkMark}>&#10004;</span>
              <span className={s.fatText}>
                <FormattedMessage id="ModalIntroductionInfo_text6" />
              </span>
              <span role="img" aria-label="man icon">
                🧑‍💼
              </span>

              <span>
                <FormattedMessage id="ModalIntroductionInfo_text7" />
              </span>
              <span className={s.fatText}>
                <FormattedMessage id="ModalIntroductionInfo_text7_1" />
              </span>
              <span role="img" aria-label="gift">
                🎁
              </span>
              <span>
                <FormattedMessage id="ModalIntroductionInfo_text7_2" />
              </span>
              <span role="img" aria-label="fairy_man">
                😱
              </span>
            </li>
          </ul>

          <p className={s.text}>
            <span role="img" aria-label="gift">
              🎁
            </span>
            <FormattedMessage id="ModalIntroductionInfo_text8_1" />
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text8_2" />
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text8_3" />
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text8_4" />
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text8_5" />
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text8_6" />
            </span>

            <span className={s.coursive}>
              <FormattedMessage id="ModalIntroductionInfo_text8_7" />
            </span>
            <span role="img" aria-label="gift">
              🎁
            </span>
          </p>
          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text9" />
          </h3>
          <p className={s.text}>
            <FormattedMessage id="ModalIntroductionInfo_text10" />
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text10_1" />
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text10_2" />
            </span>
            <span role="img" aria-label="write hand icon">
              ✍
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text10_3" />
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text10_4" />
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text10_5" />
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text10_6" />
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text10_7" />
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text10_7_1" />
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text10_7_2" />
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text10_8" />
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text10_9" />
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text10_10" />
            </span>
            {/* <span>
              <FormattedMessage id="ModalIntroductionInfo_text10_11" />
            </span> */}
          </p>
          <p className={s.text}>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text11" />
            </span>
            <span role="img" aria-label="smile">
              😄
            </span>
            <span>
              <FormattedMessage id="ModalIntroductionInfo_text11_1" />
            </span>
            <span role="img" aria-label="sign_done">
              👌
            </span>
          </p>
          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text12" />
          </h3>
          <p className={s.text}>
            <span role="img" aria-label="megafone icon">
              📣
            </span>
            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text13_1" />
            </span>
            <FormattedMessage id="ModalIntroductionInfo_text13_2" />
            <p>
              <FormattedMessage id="ModalIntroductionInfo_text13_3" />
              <span role="img" aria-label="fairy_man">
                😱
              </span>
            </p>
          </p>
          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text14" />
          </h3>

          <div className={s.containerIconWithText}>
            <div className={s.iconContainer}>
              <button className={s.buttonWithSvg}>
                <Movie className={s.svg} />
                <FormattedMessage id="TaskButtons_btnVideo" />
              </button>
            </div>
            <p
              style={
                clientWidth > 900
                  ? { marginLeft: "185px" }
                  : { marginLeft: "0", marginTop: "15px" }
              }
              className={s.text}
            >
              <FormattedMessage id="ModalIntroductionInfo_text14_1" />
            </p>
          </div>
          <p>
            <span role="img" aria-label="attention">
              ❗️
            </span>
            <FormattedMessage id="ModalIntroductionInfo_text14_2" />

            <span className={s.fatText}>
              <FormattedMessage id="ModalIntroductionInfo_text14_3" />
            </span>
            <span role="img" aria-label="attention">
              ❗️
            </span>
          </p>

          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text_15" />
          </h3>
          <div className={s.containerIconWithText}>
            <div className={s.iconContainer}>
              <button className={s.buttonWithoutSvg}>
                <FormattedMessage id="ButtonShareWithAFriendText" />
              </button>
            </div>
            <p
              style={
                clientWidth > 900
                  ? { marginLeft: "185px" }
                  : { marginLeft: "0", marginTop: "15px" }
              }
              className={s.text}
            >
              <FormattedMessage id="ModalIntroductionInfo_text15_1" />
              <span role="img" aria-label="man_with_glasses">
                😎
              </span>
            </p>
          </div>

          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text16" />
          </h3>
          <div className={s.containerIconWithText}>
            <div className={s.iconContainer}>
              <Speedometer exclusivelyPresentation={true} />
            </div>
            <p
              style={
                clientWidth > 900
                  ? { marginLeft: "85px" }
                  : { marginLeft: "0", marginTop: "15px" }
              }
              className={s.textForSpeedometer}
            >
              <FormattedMessage id="ModalIntroductionInfo_text16_1" />
            </p>
            <ul className={s.list}>
              <li className={s.listItem}>
                <FormattedMessage id="ModalIntroductionInfo_text16_2" />
              </li>
              <li className={s.listItem}>
                <FormattedMessage id="ModalIntroductionInfo_text16_3" />
              </li>
              <li className={s.listItem}>
                <FormattedMessage id="ModalIntroductionInfo_text16_4" />
              </li>
              <li className={s.listItem}>
                <FormattedMessage id="ModalIntroductionInfo_text16_5" />
              </li>
            </ul>
          </div>

          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text17" />
          </h3>
          <div className={s.containerIconWithText}>
            <div className={s.iconContainer}>
              <ScoreRate presentaionRole={true} />
            </div>
            <p
              style={
                clientWidth > 900
                  ? { marginLeft: "112px" }
                  : { marginLeft: "0", marginTop: "15px" }
              }
              className={s.textWithIcon}
            >
              <FormattedMessage id="ModalIntroductionInfo_text18" />
            </p>
          </div>
          <div className={s.containerIconWithText}>
            <div className={s.iconContainer}>
              <LivelihoodIndicator numberOfLives={3} presentaionRole={true} />
            </div>
            <p
              style={
                clientWidth > 900
                  ? { marginLeft: "115px" }
                  : { marginLeft: "0", marginTop: "15px" }
              }
              className={s.textWithIcon}
            >
              <FormattedMessage id="ModalIntroductionInfo_text19" />
            </p>
          </div>

          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text20" />
          </h3>
          <div className={s.containerIconWithText}>
            <div className={s.iconContainer}>
              {clientWidth > 900 ? (
                <Button text={languages[lang].button_share} />
              ) : (
                <button className={s.button}>
                  <FormattedMessage id="button_share" />
                </button>
              )}
            </div>
            <p
              style={
                clientWidth > 900
                  ? { marginLeft: "195px" }
                  : { marginLeft: "0", marginTop: "15px" }
              }
              className={s.textWithIconAndButton}
            >
              <FormattedMessage id="ModalIntroductionInfo_text21" />
              &#128522;
            </p>
          </div>
          <h3 className={s.subtitle}>
            <FormattedMessage id="ModalIntroductionInfo_text22" />
          </h3>

          <div className={s.containerIconWithText}>
            <div className={s.iconContainer}>
              <InfoSvg className={s.icon} />
            </div>
            <p
              style={
                clientWidth > 900
                  ? { marginLeft: "25px" }
                  : { marginTop: "15px" }
              }
              className={s.textWithIconAndInfo}
            >
              <FormattedMessage id="ModalIntroductionInfo_text23" />
            </p>
          </div>

          <div className={s.checkbox_container}>
            <input
              checked={isUserAgreeWithGameRules}
              onChange={handleChangeUserAgreeWithGameRules}
              className={s.input}
              id="agree"
              type="checkbox"
            />
            <label className={s.labelCheckbox} htmlFor="agree">
              <FormattedMessage id="ModalIntroductionInfo_text24" />
            </label>
          </div>

          <div className={s.button_container}>
            <Button
              onClickButton={startGameOrContinue}
              text={languages[lang].button_go}
              bigButton={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalIntroductionInfo;
