import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
import Speedometer from "../../components/Speedometer/Speedometer";
import { FormattedMessage } from "react-intl";
import s from "./ModalProgress.module.css";

const ModalProgress = ({ onClose = () => null, userGameVariable }) => {
  const { currentScore } = userGameVariable;
  return (
    <ModalWrapper onClose={onClose}>
      <section className={s.wrapper}>
        <WinnerCup className={s.winnerCup} width="118" />

        <h2 className={s.modalTitle}>
          <FormattedMessage id="progressTittle" />
          {currentScore >= 60 && currentScore < 90 && (
            <span role="img" aria-label="man_with_glasses">
              🥳🥳🥳
            </span>
          )}
          {currentScore >= 90 && (
            <span role="img" aria-label="congrats">
              🎉🎉🎉
            </span>
          )}
        </h2>

        <Close onClick={onClose} className={s.close} />
        <div className={s.contentWrapper}>
          {currentScore >= 54 && currentScore < 60 && (
            <p className={s.text}>
              <FormattedMessage id="congrats_preTrainee_1" />
              <span className={s.fatText}>
                <FormattedMessage id="trainee_level" />
              </span>
              <FormattedMessage id="congrats_preTrainee_2" />
              <span className={s.fatText}>
                <FormattedMessage id="congrats_preTrainee_3" />
              </span>
            </p>
          )}
          {currentScore >= 60 && currentScore < 81 && (
            <p className={s.text}>
              <FormattedMessage id="trainee_1" />
              <span className={s.fatText}>
                <FormattedMessage id="trainee_level" />
              </span>
              <FormattedMessage id="trainee_2" />
              <span className={s.fatText}>
                <FormattedMessage id="trainee_3" />
              </span>
              <FormattedMessage id="trainee_4" />
              <span className={s.fatText}>
                <FormattedMessage id="trainee_5" />
              </span>
              <span role="img" aria-label="force">
                💪
              </span>
            </p>
          )}
          {currentScore >= 81 && currentScore < 90 && (
            <p className={s.text}>
              <FormattedMessage id="congrats_preJunior_1" />
              <span className={s.fatText}>
                <FormattedMessage id="congrats_preJunior_2" />
              </span>
              <FormattedMessage id="congrats_preJunior_3" />
            </p>
          )}
          {currentScore >= 90 && (
            <p className={s.text}>
              <FormattedMessage id="junior_1" />
              <span className={s.fatText}>
                <FormattedMessage id="junior_level" />
              </span>
              <span role="img" aria-label="fire">
                💥🔥
              </span>
              <FormattedMessage id="junior_2" />
              <span role="img" aria-label="like">
                👍
              </span>
              <FormattedMessage id="junior_3" />
              <span className={s.fatText}>
                <FormattedMessage id="junior_4" />
              </span>
              <span role="img" aria-label="gift">
                🎁
              </span>
              <FormattedMessage id="junior_5" />

              <span role="img" aria-label="scare_smile">
                😱
              </span>
            </p>
          )}

          <p className={s.text_spedometr}>
            <FormattedMessage id="trainee_6" />
            👉
            <div className={s.containerIconWithText}>
              <Speedometer exclusivelyPresentation={true} />
            </div>
          </p>
        </div>
      </section>
    </ModalWrapper>
  );
};

export default ModalProgress;
