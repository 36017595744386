import React, { useEffect } from "react";
import s from "./ErrorServerRefreshStub.module.css";
import logo from "../../assets/images/logo.png";
import { FormattedMessage } from "react-intl";
const twoMinutesInMs = 120000;

const ErrorServerRefreshStub = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, twoMinutesInMs);
  }, []);

  return (
    <div className={s.container}>
      <a href="https://goit.ua/" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="logo" />
      </a>

      <p className={s.text}>
        <FormattedMessage id="ErrorServerRefresh_text" />
      </p>
    </div>
  );
};

export default ErrorServerRefreshStub;
