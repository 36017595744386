import React, { useState } from "react";
import styles from "./TaskButtons.module.css";
import { ReactComponent as Refresh } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
import Timer from "../Timer/Timer";
import VideoModal from "../VideoModal/VideoModal";

import { FormattedMessage } from "react-intl";

const TaskButtons = ({
  checkTest,
  timerEndTime,
  metadata,
  showTimer,
  hideTimer,
  clientWidth,
  openModalRefreshQuestion,
  userGameVariable,
  takeAwayOnePointFromUserАForViewingVideohint,
  currentTaskId,
  token,
}) => {
  const [isShowVideoModal, setIsShowVideoModal] = useState(false);
  const showVideoModal = () => {
    if (!showTimer) {
      setIsShowVideoModal(true);
    }
  };
  const closeVideoModal = () => {
    setIsShowVideoModal(false);
  };

  return (
    <div className={styles.buttonsContainer}>
      <button className={styles.checkButton} onClick={checkTest}>
        <FormattedMessage id="TaskButtons_btnCheck" />
      </button>

      <button
        onClick={openModalRefreshQuestion}
        className={styles.buttonWithSvg}
      >
        <Refresh className={styles.svg} />
        <FormattedMessage id="TaskButtons_btnRefresh" />
      </button>

      {(clientWidth > 1800 || !showTimer) && (
        <button
          onClick={showVideoModal}
          className={
            !showTimer ? styles.buttonWithSvg : styles.disableButtonWithSvg
          }
        >
          <Movie className={!showTimer ? styles.svg : styles.disableSvg} />
          <div className={styles.tooltip}>
            <FormattedMessage id="TaskButtonsVideo_tooltip" />
          </div>
          <FormattedMessage id="TaskButtons_btnVideo" />
        </button>
      )}

      {showTimer && <Timer timerEndTime={timerEndTime} hideTimer={hideTimer} />}

      {isShowVideoModal && (
        <VideoModal
          metadata={metadata}
          onClose={closeVideoModal}
          takeAwayOnePointFromUserАForViewingVideohint={
            takeAwayOnePointFromUserАForViewingVideohint
          }
          token={token}
          currentTaskId={currentTaskId}
          userGameVariable={userGameVariable}
        />
      )}
    </div>
  );
};

export default TaskButtons;
