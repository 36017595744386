export default {
  header_logoName: "Знайомство з HTML та CSS. ",
  header_day: "День ",
  header_question: "Питання",
  header_modul: "Заняття",
  header_text: "з",
  buttonHideTheory_text: "Приховати теорію і завдання",
  congratsModal_title: "ВІТАЄМО!",
  congratsModal_text1_1: "Ти успішно пройшов ",
  congratsModal_text1_2: "-е заняття підготовчих завдань до домашньої роботи!",
  congratsModal_text2: "Твої результати:",
  congratsModal_text3: " правильних відповідей",
  congratsModal_text5_1: "Ви пройшли перший день на ",
  congratsModal_text5_2: "",
  congratsModal_text5_3: "швидше ",
  congratsModal_text5_4:
    "Теперь у вас есть доступ к просмотру обучающего видео Вани Лапы, где он показывает ",
  congratsModal_text5_5: "",
  congratsModal_text5_6: "",
  congratsModal_text5_7: "",
  congratsModal_text5_8: "",
  congratsModal_text6_1: "",
  congratsModal_text6_2: "",
  congratsModal_text6_3: "",
  congratsModal_text6_4: "",
  congratsModal_text6_5: "",
  congratsModal_text6_6: "",
  congratsModal_text6_7: "",
  congratsModal_text7_1: "",
  congratsModal_text7_2: "",
  congratsModal_text7_3: "",
  congratsModal_text7_4: "",
  congratsModal_text7_5: "",
  congratsModal_text7_6: "",
  congratsModal_text7_7: "",
  congratsModal_text7_8: "",
  congratsModal_text8_1: "",
  congratsModal_text8_2: "",
  congratsModal_text8_3: "",
  congratsModal_text8_4: "",
  congratsModal_text8_5: "",
  congratsModal_text8_6: "",
  congratsModal_title_final: "",
  congratsModal_text9_1: "",
  congratsModal_text9_2: "",
  congratsModal_text9_3: "",
  congratsModal_text9_4: "",
  congratsModal_text9_5: "",
  congratsModal_text9_6: "",
  congratsModal_text9_7: "",
  congratsModal_text9_8: "",
  congratsModal_text9_9: "",
  congratsModal_text9_10: "",
  congratsModal_text9_11: "",
  ErrorInvalidTokenOrBlock_text: "Не валідне посилання.",
  ErrorServerRefresh_text:
    "Технічні роботи на сервері, сервер перезавантажується, оновіть сторінку через 1-2 хвилини.",
  ErrorServerStub_text: "Технічні роботи на сервері, спробуйте знову пізніше.",
  ErrorStub_text: "Не валідне посилання.",
  footer_text: "Політика конфіденційності",
  MobileAndTabletStub_text: "Розшир браузер або зайди з десктопа.",
  ModalAutotranslator_title: "Вимкніть автоперекладач !!!",
  ModalAutotranslator_text:
    "Тому що програма не зможе коректно перевірити Ваше завдання.",
  ModalRefreshQuestion_title: "Ви точно хочете скинути код в початковий стан?",
  ModalRefreshQuestion_yes: "Так",
  ModalRefreshQuestion_no: "Ні",
  ModalTaskCriteria_title: "Результат:",
  ModalTaskCriteria_success: "Успішно:",
  ModalTaskCriteria_errors: "Помилки:",
  ModalTaskCriteria_buttonContinue: "Продовжити",
  ModalTaskCriteria_buttonNextQuestion: "Перейти до наступного питання",
  ModalTaskCriteria_buttonOk: "Ок",
  TaskButtons_btnCheck: "Перевірити",
  TaskButtons_btnRefresh: "Скинути",
  markup: "Макет",
  click_picture: "* щоби збільшити, клікни по картинці",
  Timer_text: "Доступ до відеопідказки через ",
  callToastify_taskAlreadySolved: "Це завдання вже вирішено!",
  callToastify_text1:
    "У цьому завданні HTML і CSS редактори не активні, використовуй тільки редактор JavaScript",
  callToastify_text2:
    "У цьому завданні HTML і JavaScript редактори не активні, використовуй тільки редактор CSS",
  callToastify_text3:
    "У цьому завданні CSS і JavaScript редактори не активні, використовуй тільки редактор HTML",
  callToastify_text4:
    "У цьому завданні HTML редактор не активний, використовуй тільки редактор CSS",
  callToastify_text5:
    "У цьому завданні CSS редактор не активний, використовуй тільки редактор HTML",
  callToastify_text6:
    "У цьому завданні HTML редактор не активний, використовуй тільки редактор JavaScript",
  selectCurrentQuestion_text: "Питання",
  modalPolitics_title: "ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ",
  modalPolitics_text:
    "Погодившись брати участь в марафоні ви надаєте згоду на обробку персональних даних, погоджуєтеся з запропонованими нами каналами комунікації, з можливістю отримання розсилок та комерційних пропозицій. Беручи дану політику ви також підтверджуєте, що ознайомилися з переліком своїх прав як суб'єкта даних.",
  modalPolitics_subtitle1: "1. ЗБІР ОСОБИСТОЇ ІНФОРМАЦІЇ",
  modalPolitics_text1_1:
    "1.1. Під час заповнення контактних форм при реєстрації ми просимо вас повідомити необхідну особисту інформацію в обсязі, який є необхідним для надання послуг.",
  modalPolitics_text1_2:
    "1.2. Файли cookie - коли ви переходите по посиланнях, ми відправляємо один або кілька файлів cookie на ваш комп'ютер або інший пристрій.Файли cookie використовуються для того, щоб підвищувати якість послуг,що надаються: зберігати налаштування користувача.",
  modalPolitics_text1_3:
    "1.3. Інформація про відвідування - при доступі на сайт наші сервери автоматично можуть записувати певну інформацію. Ці журнали сервера можуть містити таку інформацію, як веб-запит, IP-адреса, тип і мова браузера, дату і час запиту.",
  modalPolitics_subtitle2: "2. ЗАХИСТ ІНФОРМАЦІЇ",
  modalPolitics_text2_1:
    "2.1. Ми вживаємо всіх необхідних заходів для захисту даних від несанкціонованого доступу, зміни, розкриття чи знищення. До цих заходів відносяться, зокрема, внутрішня перевірка процесів збору, зберігання і обробки даних і заходів безпеки, включаючи відповідне шифрування і заходи щодо забезпечення фізичної безпеки даних для запобігання несанкціонованого доступу.",
  modalPolitics_subtitle3: "3. ЗМІНА ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ",
  modalPolitics_text3_1:
    "3.1. Дана політика конфіденційності може час від часу змінюватися.Зміни, що вносяться до політики конфіденційності будуть опубліковані в цьому документі.",
  ScoreRate_text: "Балів: ",
  ScoreRateTooltip:
    "Кількість зароблених балів. За кожну виконану задачу ви отримуєте 2 бали. Збираючи бали, ви відкриваєте доступ до рівнів, за які отримуєте доступ до бонусів. Детальніше про бонуси в кнопці Інформація.",
  LivelihoodIndicatorTooltip:
    "Кількість доступних життів. Життя згорають, якщо ви не вирішили завдання цього дня до 18:00. Якщо згорять всі життя, ви не зможете більше вирішувати завдання.",
  TaskButtonsVideo_tooltip: "",
  LeftSidePanel_title: "Ваші досягнення",
  LeftSidePanel_progress: "Прогрес:",
  LeftSidePanel_level: "Ваш рівень:",
  LeftSidePanel_bonus: "Ваші бонуси:",
  LeftSidePanel_nextLevel: "До наступного рівня балів:",
  bonus: "Бонус ",
  videoLinksToBonus_bonusSoftSkills:
    "https://www.youtube.com/watch?v=232y1qYaYO4&t=1s",
  videoLinksToBonus_bonusFindJob: "https://www.youtube.com/watch?v=So9BjQpaHKU",
  videoLinksToBonus_linkedin: "https://www.youtube.com/watch?v=yAGRoxXM9EM",
  LeftSidePanel_inviteFriend: "Запрошених друзів:",
  LeftSidePanel_lifes: "Доступно життів:",
  ModalIntroductionInfo_title: "Правила марафону",
  ModalIntroductionInfo_text1:
    " прочитайте ці правила #уважно. Це допоможе пройти марафон-гру до кінця.",
  ModalIntroductionInfo_text0: "Старт",
  ModalIntroductionInfo_text2_1: "Отже, зараз ви на ",
  ModalIntroductionInfo_text2_2: "1-му рівні - Hовачок (Beginner) -",
  ModalIntroductionInfo_text2_3:
    " вирішуючи завдання кожен день, ви заробляєте бали і можете відкрити з їх допомогою доступ до крутих бонусів:",
  ModalIntroductionInfo_text3: "2 рівень - Trainee",
  ModalIntroductionInfo_text4: "",
  ModalIntroductionInfo_text4_1: "",
  ModalIntroductionInfo_text4_2: "",
  ModalIntroductionInfo_text5: "",
  ModalIntroductionInfo_text6: "3 рівень - Junior -",
  ModalIntroductionInfo_text7: "",
  ModalIntroductionInfo_text7_1: "",
  ModalIntroductionInfo_text7_2: "",
  ModalIntroductionInfo_text8_1: "",
  ModalIntroductionInfo_text8_2: "",
  ModalIntroductionInfo_text8_3: "",
  ModalIntroductionInfo_text8_4: "",
  ModalIntroductionInfo_text8_5: "",
  ModalIntroductionInfo_text8_6: "",
  ModalIntroductionInfo_text8_7: "",
  ModalIntroductionInfo_text9: "Що вам потрібно робити",
  ModalIntroductionInfo_text10: "Все просто: ",
  ModalIntroductionInfo_text10_1:
    "вирішуйте завдання кожного дня і заробляйте бали. ",
  ModalIntroductionInfo_text10_2: "1 завдання = 2 бали. ",
  ModalIntroductionInfo_text10_3: "Зараз вам нараховано ",
  ModalIntroductionInfo_text10_4: "30 стартових балів, ",
  ModalIntroductionInfo_text10_5: "а ваш рівень ",
  ModalIntroductionInfo_text10_6: "- Beginner. ",
  ModalIntroductionInfo_text10_7:
    "Щоб відкрити перший бонус, вам потрібно отримати ",
  ModalIntroductionInfo_text10_7_1: "",
  ModalIntroductionInfo_text10_7_2: "",
  ModalIntroductionInfo_text10_8: "50 балів. ",
  ModalIntroductionInfo_text10_9: "Другий бонус - ",
  ModalIntroductionInfo_text10_10: "80 балів. ",
  ModalIntroductionInfo_text10_11:
    "Щоб поборотися за третій - потрібно пройти все до кінця.",
  ModalIntroductionInfo_text11: "",
  ModalIntroductionInfo_text11_1: "",
  ModalIntroductionInfo_text12: "Однак, не все так просто...",
  ModalIntroductionInfo_text13_1: "У вас є всього 5 життів. ",
  ModalIntroductionInfo_text13_2: "",
  ModalIntroductionInfo_text13_3: "",
  ModalIntroductionInfo_text14: "",
  ModalIntroductionInfo_text14_1: "",
  ModalIntroductionInfo_text14_2: "",
  ModalIntroductionInfo_text14_3: "",
  TaskButtons_btnVideo: "Відеопідказка",
  button_share: "Поділитись з другом",
  button_go: "Погнали!",
  button_watch: "",
  cool_instruction: "Крутая инструкция ",
  youtube_1: "",
  youtube_2: "",
  youtube_3: "",
  youtube_4: "",
  youtube_5: "",
  ButtonShareWithAFriendText: "Поділитись з другом",
  ModalIntroductionInfo_text_15: "Як ще ви можете заробити бали",
  ModalIntroductionInfo_text15_1: "",
  ModalIntroductionInfo_text16: "Як відстежити свій прогрес",
  ModalIntroductionInfo_text16_1:
    "- універсальна кнопка з інформацією про ваш прогрес. Натиснувши на неї, ви можете подивитися:",
  ModalIntroductionInfo_text16_2: "на якому рівні перебуваєте;",
  ModalIntroductionInfo_text16_3:
    "скільки балів заробили зараз і скільки залишилося до наступного рівня;",
  ModalIntroductionInfo_text16_4:
    "свої бонуси, коли досягнете рівней Trainee і Junior;",
  ModalIntroductionInfo_text16_5: "життя - скільки у вас залишилося.",
  ModalIntroductionInfo_text17: "Швидкий доступ у верхній панелі",
  ModalIntroductionInfo_text18: "- кількість балів.",
  ModalIntroductionInfo_text19: "      - ваші життя.",
  ModalIntroductionInfo_text20: "Ваша реферальна кнопка",
  ModalIntroductionInfo_text21:
    "це ваша персональна кнопка для запрошення друга в гру. Вона генерує реферальне посилання, яке ви можете відправити друзям. Це дозволить їм приєднатися до гри, а ще заробити додаткові 5 балів кожному з вас. Вирішуйте завдання разом (тільки цур не підглядати!) - так цікавіше. ",
  ModalIntroductionInfo_text22: "Якщо ви щось пропустили...",
  ModalIntroductionInfo_text23:
    "Ви завжди можете відкрити це вікно знову, натиснувши на значок інформації в нижній панелі.",
  ModalIntroductionInfo_text24: "Я прочитав/ла правила гри, мені все зрозуміло",
  toastifyFunction_error:
    "Спершу прочитайте правила гри і поставте галочку, що ознайомились з правилами!",
  ToastifyFunction_isNotTelegramUser:
    "Не правильний токен, даний користувач не зареєстрований в telegram!",
  ErrorEndedLives_text:
    "Нам шкода, але гра для вас закінчилася. Щоб вивчити програмування, потрібно регулярно займатися. Без щоденних занять не вийде вивчити HTML/CSS.",
  progressTittle: "",
  trainee_1: "",
  trainee_2: "",
  trainee_3: "",
  trainee_4: "",
  trainee_5: "",
  trainee_6: "",
  trainee_level: "",
  junior_1: "",
  junior_2: "",
  junior_3: "",
  junior_4: "",
  junior_5: "",
  junior_level: "",
  lifeWithdrawalNotification1: "",
  lifeWithdrawalNotification2: "",
  lifeWithdrawalNotification3: "",
  ModalShareWithFriend_title: "",
  ModalShareWithFriend_mainText: "",
  ModalShareWithFriend_mainText2_1: "",
  ModalShareWithFriend_mainText2_2: "",
  ModalShareWithFriend_refText1: "",
  ModalShareWithFriend_refText2: "",
  ModalInvitedFriend_text: "",
  ModalRefInvited_text: "",
  LeftSidePanel_video: "",
  video: "",
  taskSuccessesNotification1: "",
  taskSuccessesNotification2: "",
  taskSuccessesNotification3: "",
  taskSuccessesNotification4: "",
  taskSuccessesNotification5: "",
  taskSuccessesNotification6: "",
  taskSuccessesNotification7: "",
  taskSuccessesNotification8: "",
  taskSuccessesNotification9: "",
  taskSuccessesNotification10: "",
  congrats_preTrainee_1: "",
  congrats_preTrainee_2: "",
  congrats_preTrainee_3: "",
  congrats_preJunior_1: "",
  congrats_preJunior_2: "",
  congrats_preJunior_3: "",
};
