import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import logo from "../../assets/images/logo.png";
import s from "./PreviewModal.module.css";

const PreviewModal = ({ onClose, cvLink }) => {
  return (
    <ModalWrapper onClose={onClose}>
      
      <div className={s.wrapper}>
      <Close onClick={onClose} className={s.close} />
        <img className={s.img} src={cvLink || logo} alt="cvlink" />
      </div>
    </ModalWrapper>
  );
};

export default PreviewModal;
