import React from "react";
import { FormattedMessage } from "react-intl";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import TextAreaWithCopy from "../../components/TextAreaWithCopy/TextAreaWithCopy";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";

import s from "./ModalShareWithFriend.module.css";

const ModalShareWithFriend = ({ onClose = () => null, telegramUserInfo }) => {
  let linkToCopy = `https://t.me/goit_html_marathon_bot?start=FROM-${telegramUserInfo.userId}__SRC-REF`;

  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.modal_wrapper}>
        <Close onClick={onClose} className={s.close} />

        <h2 className={s.title}>
          <FormattedMessage id="ModalShareWithFriend_title" />
        </h2>

        <div className={s.content}>
          <p className={s.text}>
            <FormattedMessage id="ModalShareWithFriend_mainText" />
            &#128522;
          </p>

          <TextAreaWithCopy linkToCopy={linkToCopy} />
          <p className={s.text} style={{ marginTop: "25px" }}>
            <span className={s.fatText}>
              <FormattedMessage id="ModalShareWithFriend_mainText2_1" />
            </span>
            <FormattedMessage id="ModalShareWithFriend_mainText2_2" />
          </p>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalShareWithFriend;
