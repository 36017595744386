import React, { useEffect, useCallback, useContext } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import * as API from "../../services/api";
import { toast } from "react-toastify";
import languages from "../../languages";
import Context from "../../context/context";
// import s from "./VideoModal.module.css";

const defaultVideoLink =
  "https://www.youtube.com/embed/wrw0UCU66GE?list=PLViULGko0FdhfDVrVR1q02egcPGfo3TDr";

const VideoModal = ({
  onClose,
  metadata,
  token,
  currentTaskId,
  userGameVariable,
  takeAwayOnePointFromUserАForViewingVideohint,
}) => {
  const context = useContext(Context);
  const { lang } = context;

  const callToastifyLifeWithdrawalNotification = useCallback(() => {
    const index = Math.floor(Math.random() * Math.floor(3));
    let arrToastify;
    arrToastify = [
      languages[lang].lifeWithdrawalNotification1,
      languages[lang].lifeWithdrawalNotification2,
      languages[lang].lifeWithdrawalNotification3,
    ];
    toast.error(arrToastify[index], {
      autoClose: 5000,
    });
  }, [lang]);

  useEffect(() => {
    API.userSeeVideoHelpRequest(token, currentTaskId).then((res) => {
      if (
        res.data.success &&
        !userGameVariable.seenVideoHelpTaskIdArray.includes(currentTaskId)
      ) {
        takeAwayOnePointFromUserАForViewingVideohint();
        callToastifyLifeWithdrawalNotification();
      }
    });
  }, [
    callToastifyLifeWithdrawalNotification,
    currentTaskId,
    takeAwayOnePointFromUserАForViewingVideohint,
    token,
    userGameVariable.seenVideoHelpTaskIdArray,
  ]);

  return (
    <ModalWrapper onClose={onClose}>
      {/* <video controls className={s.video}>
        <source src={defaultVideoLink} type="video/mp4" />
        Ваш браузер не поддерживает элемент <code>video</code>.
      </video> */}

      <iframe
        title="video"
        width="90%"
        height="90%"
        src={(metadata && metadata.video) || defaultVideoLink}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </ModalWrapper>
  );
};

export default VideoModal;
