import React from "react";
import { ReactComponent as SpeedometerSvg } from "../../assets/icons/speedometer/speedometer.svg";
import s from "./Speedometer.module.css";

const Speedometer = ({
  onClickSpeedometer = () => null,
  exclusivelyPresentation = false,
  mini = false,
}) => {
  return (
    <div
      className={
        exclusivelyPresentation
          ? mini
            ? s.containerPresentationMini
            : s.containerPresentation
          : s.container
      }
      onClick={onClickSpeedometer}
    >
      <SpeedometerSvg className={mini ? s.iconMini : s.icon} />
    </div>
  );
};

export default Speedometer;
