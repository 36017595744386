import React from "react";
import s from "./MobileAndTabletStub.module.css";
import logo from "../../assets/images/logo.png";
import { FormattedMessage } from "react-intl";

const MobileAndTabletStub = () => {
  return (
    <div className={s.container}>
      <img className={s.logo} src={logo} alt="logo" />
      <p className={s.text}>
        <FormattedMessage id="MobileAndTabletStub_text" />
      </p>
    </div>
  );
};

export default MobileAndTabletStub;
