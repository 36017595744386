import React from "react";
import s from "./ScoreRate.module.css";
import { FormattedMessage } from "react-intl";
import userMarathonVariables from "../../utils/userMarathonVariables";

const ScoreRate = ({
  score = 30,
  presentaionRole = false,
  taskblockMetadata,
}) => {
  return (
    <div className={presentaionRole ? s.presentationContainer : s.container}>
      <FormattedMessage id="ScoreRate_text" />
      <span className={s.count}>{score}</span> /{userMarathonVariables.maxScore}
      <div
        className={
          taskblockMetadata && taskblockMetadata.allowSwitchLanguage
            ? s.tooltip
            : s.tooltipMoved
        }
      >
        <FormattedMessage id="ScoreRateTooltip" />
      </div>
    </div>
  );
};

export default ScoreRate;
