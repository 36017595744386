import React from "react";
import s from "./LivelihoodIndicator.module.css";
import Heart from "../Heart/Heart";
import { FormattedMessage } from "react-intl";

const LivelihoodIndicator = ({
  numberOfLives,
  presentaionRole = false,
  taskblockMetadata,
}) => {
  if (numberOfLives === 0) {
    return (
      <div className={presentaionRole ? s.presentationContainer : s.container}>
        <Heart />
        <Heart />
        <Heart />
        <div
          className={
            taskblockMetadata && taskblockMetadata.allowSwitchLanguage
              ? s.tooltip
              : s.tooltipMoved
          }
        >
          <FormattedMessage id="LivelihoodIndicatorTooltip" />
        </div>
      </div>
    );
  } else if (numberOfLives === 1) {
    return (
      <div className={presentaionRole ? s.presentationContainer : s.container}>
        <Heart mode="burnHeart" />
        <Heart />
        <Heart />

        <div
          className={
            taskblockMetadata && taskblockMetadata.allowSwitchLanguage
              ? s.tooltip
              : s.tooltipMoved
          }
        >
          <FormattedMessage id="LivelihoodIndicatorTooltip" />
        </div>
      </div>
    );
  } else if (numberOfLives === 2) {
    return (
      <div className={presentaionRole ? s.presentationContainer : s.container}>
        <Heart mode="burnHeart" />
        <Heart mode="burnHeart" />
        <Heart />

        <div
          className={
            taskblockMetadata && taskblockMetadata.allowSwitchLanguage
              ? s.tooltip
              : s.tooltipMoved
          }
        >
          <FormattedMessage id="LivelihoodIndicatorTooltip" />
        </div>
      </div>
    );
  } else if (numberOfLives === 3) {
    return (
      <div className={presentaionRole ? s.presentationContainer : s.container}>
        <Heart mode="burnHeart" />
        <Heart mode="burnHeart" />
        <Heart mode="burnHeart" />
        <div
          className={
            taskblockMetadata && taskblockMetadata.allowSwitchLanguage
              ? s.tooltip
              : s.tooltipMoved
          }
        >
          <FormattedMessage id="LivelihoodIndicatorTooltip" />
        </div>
      </div>
    );
  }
};

export default LivelihoodIndicator;
